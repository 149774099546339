
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  PropType,
  ref,
} from 'vue';
import { useStore } from 'vuex';

/** Servicios */
import { NewService } from '@/services/general.service';
import { EncryptService } from '@/services/encrypt.service';

/** Interfaces */
import {
  AttributesGeneralI,
  PortfolioI,
  AgreementsI,
  NegotiationsI,
  BusinessI,
  LinceObligationI,
  RepositoryObligationI,
  ViewDetailPlanI,
  ClosedObligationI,
} from '@/interfaces/general.interface';
import { LocationI } from '@/interfaces/auth.interface';
import { DataPayPlaceToPayI } from '@/interfaces/payment-detail-place-to-pay.interface';
import {
  PlaceToPayRequestI,
  PlaceToPayResponseI,
} from '@/interfaces/placetopay.interface';
import { DataFeesSummary } from '@/interfaces/payPlan.interface';

/** Utilidades */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import {
  disableEncryption,
  showErrors,
  isAppleDevice,
  renameObjectProperties,
} from '@/utils/general-utils';
import {
  DataGeneralTypes,
  ObligationSelectedTypes,
} from '@/utils/mutation-types';
import { ObligationTypes } from '@/utils/multi-pdf-types';
import { ApmBase } from '@elastic/apm-rum';

/**Composables */
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';
import { SimulateNegotiationI } from '@/interfaces/negotiation.interface';

export default defineComponent({
  name: 'MainView',
  props: {
    client: {
      type: Object as PropType<AttributesGeneralI>,
      required: false,
    },
    isLoadingObligations: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoadingPaymentStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ModalNegotiation: defineAsyncComponent(
      () => import('@/components/shared/ModalNegotiation.vue')
    ),
    ModalDetails: defineAsyncComponent(
      () => import('@/components/shared/ModalDetails.vue')
    ),
    ModalGratitude: defineAsyncComponent(
      () => import('@/components/shared/ModalGratitude.vue')
    ),
    ObligationCard: defineAsyncComponent(
      () => import('@/components/cards/ObligationCard.vue')
    ),
    AgreementCard: defineAsyncComponent(
      () => import('@/components/cards/AgreementCard.vue')
    ),
    NegotiationCard: defineAsyncComponent(
      () => import('@/components/cards/NegotiationCard.vue')
    ),
    LinceObligationCard: defineAsyncComponent(
      () => import('@/components/cards/LinceObligationCard.vue')
    ),
    DannRegionalCard: defineAsyncComponent(
      () => import('@/components/cards/DannRegionalCard.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
    ModalClientDanRegional: defineAsyncComponent(
      () => import('@/components/shared/ModalClientDanRegional.vue')
    ),
  },
  emits: ['fetch-data-genaral'],
  setup(_props, { emit }) {
    const store = useStore();
    const showModal = ref<boolean>(false);
    const showModalDetails = ref<boolean>(false);
    const paymentDetail = ref<DataPayPlaceToPayI>({});
    const payPlans = ref([]);
    const savedNegotiationId = ref<string>('');
    const showModalGratitude = ref<boolean>(false);
    const payPlan = ref<unknown>({});
    const portfolioSelected = ref<unknown>({});
    const agreementSelected = ref<unknown>({});
    const loadingQuotaSummary = ref<boolean>(false);
    const showModalDanRegional = ref<boolean>(true);
    const iPhoneLink = ref<unknown>({});
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, addTransaction, endTransactionApm } =
      useElasticTransactionApm();

    const portfolios_ = computed((): PortfolioI[] => {
      const portfolios: PortfolioI[] =
        store.state.dataGeneral?.portfolios || [];

      return portfolios.length > 0 ? portfolios : [];
    });

    const agreements_ = computed(() => {
      const agreements: AgreementsI[] =
        store.state.dataGeneral?.agreements || [];

      return agreements.length > 0 ? agreements : [];
    });

    const negotiations_ = computed(() => {
      const agreements: NegotiationsI[] =
        store.state.dataGeneral?.negotiations || [];

      return agreements.length > 0 ? agreements : [];
    });

    const openLinceObligations = computed((): LinceObligationI[] => {
      const obligations: LinceObligationI[] =
        store.state.dataGeneral?.lince_obligations || [];

      return obligations.length > 0 ? obligations : [];
    });

    const openRepositoryObligations = computed((): RepositoryObligationI[] => {
      const obligations: RepositoryObligationI[] =
        store.state.dataGeneral?.repository_obligations || [];

      return obligations.length > 0 ? obligations : [];
    });

    const closeObligations = computed((): ClosedObligationI[] => {
      return store.state.dataGeneral?.closed_obligations || [];
    });

    const filterPortfolios = computed((): PortfolioI[] => {
      return [...portfolios_.value]?.sort((a: PortfolioI, b: PortfolioI) => {
        return (
          Date.parse(b.business[0]?.date_ingress_sab2) -
          Date.parse(a.business[0]?.date_ingress_sab2)
        );
      });
    });

    const location = computed((): LocationI => {
      return store.state.location;
    });

    const timestamp = computed(() => {
      return store.getters.getTimestamp;
    });

    const authUserId = computed((): string => {
      return store?.state?.authUser?.client_id || '';
    });

    const authenticatedUser = computed(() => {
      return store?.state?.authUser;
    });

    const isLinceClient = computed((): boolean => {
      return store?.state?.linceId != null;
    });

    const isDannRegional = computed((): boolean => {
      return store?.state?.dataGeneral?.repository_client || false;
    });

    const isDannObligation = computed((): boolean => {
      return (
        paymentDetail.value.obligationType === ObligationTypes.AVAL_REPOSITORY
      );
    });

    const validateAnchor = (anchorId: number | null): boolean => {
      return Boolean(anchorId && anchorId > 0);
    };

    const viewModalFondeadores = computed((): boolean => {
      return (
        !openRepositoryObligations.value.some((item) =>
          validateAnchor(item.anchor_id)
        ) &&
        !closeObligations.value.some((item) => validateAnchor(item.anchor_id))
      );
    });

    // Obtener detalle de la obligación
    const viewDetailPlan = (dataViewDetail: ViewDetailPlanI) => {
      const { agreement, obligationType } = dataViewDetail;
      const dataAgreement: DataPayPlaceToPayI = {
        valueMin: 0,
        valueMax: +agreement.full_total_payment,
        limit_date_payment: '',
        register_type_id: agreement.register_type_id,
        register_type_state: agreement.register_type_state,
        default_collect_account: String(
          agreement.default_collect_account
        ).toString(),
        code_pse: agreement.code_pse,
        reference: '',
        detail: '',
        coupon_type: '',
        registerType: agreement.register_type.toLowerCase(),
        commerceName: agreement.commerce_name,
        basedOn: '',
        portfolioId: '',
        businessId: '',
        urlLogo: agreement.url_logo,
        lince: false,
        created_at: agreement.created_at,
        obligationType,
        current_debt_value: agreement.current_debt_value,
        notes: agreement?.notes,
        detailFullTotalPayment: agreement.detail_full_total_payment,
      };
      store.commit(ObligationSelectedTypes.SET_OBLIGATION_SELECTED, agreement);
      const { registerType } = dataAgreement;
      const isEndorsement = registerType === 'aval';
      const isAgreement = registerType === 'acuerdo';
      dataAgreement.lince = isEndorsement;
      dataAgreement.coupon_type = isAgreement ? 'A' : 'N';

      const dataFeesSummary: DataFeesSummary = {
        client_id: isEndorsement
          ? agreement?.lince_user_id?.toString()
          : store.state.dataGeneral.client_id.toString(),
        type: registerType,
        obligation_id: isEndorsement
          ? agreement?.lince_aval_id
          : agreement.register_type_id,
      };

      customTransactioncApm(
        $apm,
        'click - mas-informacion',
        'custom',
        dataFeesSummary
      );

      if (agreement.payment_information.length > 1) {
        const firstAgreement = agreement.payment_information.find((item) => {
          return item.sub_register_type?.toLowerCase() !== 'total';
        });

        if (firstAgreement) {
          dataAgreement.valueMin = +firstAgreement.value;
          dataAgreement.limit_date_payment = firstAgreement.limit_date_payment;
          dataAgreement.reference = firstAgreement.reference;
          dataAgreement.detail = firstAgreement.detail;
        }
      } else {
        dataAgreement.valueMin = +agreement.payment_information[0].value;
        dataAgreement.limit_date_payment =
          agreement.payment_information[0].limit_date_payment;
        dataAgreement.reference = agreement.payment_information[0].reference;
        dataAgreement.detail = agreement.payment_information[0].detail;
      }

      if (!isEndorsement && agreement.business.length > 0) {
        const [firstBusiness] = agreement.business;
        dataAgreement.basedOn = firstBusiness?.based_on;
        dataAgreement.portfolioId = firstBusiness?.portfolio_id;
        dataAgreement.businessId = firstBusiness?.business_id;
      }

      paymentDetail.value = dataAgreement;

      if (!isDannObligation.value) {
        getFeesSummary(dataFeesSummary);
      }

      openModalDetails();
    };

    const getFeesSummary = (payload: DataFeesSummary) => {
      payPlan.value = {};
      loadingQuotaSummary.value = true;
      new NewService()
        .getFeesSummary(payload)
        .then((response) => {
          const data = response.data;
          const decrypted = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const attributes = decrypted?.data?.attributes[0];
          addTransaction({
            feeNumber: attributes.fees.length,
            obligationId: attributes.obligation_id,
            registerType: attributes.register_type,
            registertypeId: attributes.register_type_id,
          });
          payPlan.value = decrypted;
          loadingQuotaSummary.value = false;
        })
        .catch((error) => {
          loadingQuotaSummary.value = false;
          showErrors(
            error,
            'Se produjo un error al obtener el resúmen de cuotas'
          );
        })
        .finally(() => endTransactionApm());
    };

    const makePayment = (
      agreement: AgreementsI | NegotiationsI | LinceObligationI
    ) => {
      let reference = '';
      let detail = '';
      let value = 0;

      showSwalLoading();

      if (agreement.payment_information.length > 1) {
        const firstAgreement = agreement.payment_information.find((item) => {
          return item.sub_register_type?.toLowerCase() !== 'total';
        });
        if (firstAgreement) {
          reference = firstAgreement.reference;
          detail = firstAgreement.detail;
          value = firstAgreement.value;
        }
      } else {
        reference = agreement.payment_information[0].reference;
        detail = agreement.payment_information[0].detail;
        value = agreement.payment_information[0].value;
      }

      const dataPay: PlaceToPayRequestI = {
        document_type: authenticatedUser.value.document_type,
        identification: authenticatedUser.value.identification,
        payment_platform: `placetopay_${agreement.code_pse}`,
        reference: reference,
        description: detail,
        amount: value,
        ip_address: location.value?.ip || '8.8.8.8',
        user_agent:
          location.value?.uag ||
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62',
      };

      customTransactioncApm(
        $apm,
        'click - realizar-pago-minimo-14',
        'custom',
        dataPay
      );
      store
        .dispatch('payFee', dataPay)
        .then((res: PlaceToPayResponseI) => {
          closeSwal();
          const { attributes, message, success } = res;
          addTransaction({
            processUrl: attributes.processUrl,
            requestId: attributes.requestId,
          });
          if (success) {
            const { status, processUrl } = attributes;
            if (status?.status?.toLowerCase() === 'ok') {
              isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
            } else {
              showSwalError(status.message);
            }
          } else {
            showSwalError(message);
          }
        })
        .catch((error) =>
          showErrors(
            error,
            'Se produjo un error al redireccionar a la plataforma de pago online PSE.'
          )
        )
        .finally(() => endTransactionApm());
    };

    const negotiation = (portfolio: PortfolioI) => {
      showSwalLoading();
      portfolioSelected.value = portfolio;

      const businessListId = portfolio.business.map((business: BusinessI) => {
        return business.business_id;
      });
      customTransactioncApm($apm, 'click - conozcalas', 'custom', portfolio);
      store
        .dispatch('getSimulation', {
          client_id: authUserId.value,
          business: businessListId,
          portfolios_id: portfolio?.business.map((item) => item.portfolio_id),
        })
        .then((res: SimulateNegotiationI[]) => {
          getResponseSimulator(res);
          openModal();
          closeSwal();
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al consultar los datos de negociación.'
          );
        })
        .finally(() => endTransactionApm());
    };

    const closeModal = () => {
      showModal.value = false;
      showModalDetails.value = false;
      showModalGratitude.value = false;
      savedNegotiationId.value = '';
    };

    const openModalGratitude = (negotiationId: string) => {
      showModalGratitude.value = true;
      savedNegotiationId.value = negotiationId;
    };

    const closeModalGratitude = (): void => {
      closeModal();
      store.commit(DataGeneralTypes.SET_DATA_GENERAL, Object.create({}));
      emit('fetch-data-genaral');
    };

    const openModalDetails = () => {
      showModalDetails.value = true;
    };

    const openModal = () => {
      showModal.value = true;
    };

    const closeModalDanRegional = (): void => {
      showModalDanRegional.value = false;
    };

    const countObligations = () => {
      const total =
        negotiations_.value.length +
        portfolios_.value.length +
        agreements_.value.length +
        negotiations_.value.length +
        openLinceObligations.value.length +
        openRepositoryObligations.value.length;

      return total === 0;
    };

    const getResponseSimulator = (res: SimulateNegotiationI[]) => {
      try {
        let result = Object.create({});
        const data: SimulateNegotiationI[] = [];

        for (let i = 0; i < res.length; i++) {
          data[i] = Object.assign({}, res[i]);
        }

        data.forEach((item: SimulateNegotiationI, index) => {
          delete item.negotiation;
          Object.assign(
            result,
            renameObjectProperties(item, 'pago' + (index + 1).toString())
          );
        });
        addTransaction(result);
        return result;
      } catch (error) {
        return {};
      }
    };

    return {
      authUserId,
      agreements_,
      agreementSelected,
      closeModal,
      filterPortfolios,
      getFeesSummary,
      isLinceClient,
      location,
      loadingQuotaSummary,
      makePayment,
      negotiations_,
      negotiation,
      openModal,
      openLinceObligations,
      openRepositoryObligations,
      openModalGratitude,
      openModalDetails,
      paymentDetail,
      payPlan,
      payPlans,
      portfolios_,
      portfolioSelected,
      showModal,
      showModalDetails,
      showModalGratitude,
      timestamp,
      viewDetailPlan,
      isDannRegional,
      isDannObligation,
      showModalDanRegional,
      closeModalDanRegional,
      viewModalFondeadores,
      savedNegotiationId,
      closeModalGratitude,
      countObligations,
      iPhoneLink,
    };
  },
});
