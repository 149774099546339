/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

export class FinancialTipsService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().mock();
  }

  async getFinancialTips() {
    return this.service.get('financialTips.json');
  }
}
