import { ActionTree } from 'vuex';
import { CouponStateInterface } from './state';
import { State as StateInterface } from '@/store/index';
import { EncryptService } from '@/services/encrypt.service';
import { disableEncryption } from '@/utils/general-utils';
import { CouponService } from '../services/coupon.service';
import {
  CouponDataI,
  CouponParamsI,
  ResponseCouponGeneralI,
} from '../interfaces/digital-coupon.interface';

const actions: ActionTree<CouponStateInterface, StateInterface> = {
  async getDigitalCoupon({ commit }, payload: CouponParamsI) {
    const { data } = await new CouponService().getDigitalCoupon(payload);
    const decrypted: ResponseCouponGeneralI = disableEncryption()
      ? data
      : EncryptService.decrypt(data);

    const response: CouponDataI = decrypted?.data?.attributes;

    return response;
  },
};

export default actions;
