/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

/** Interface */
import {
  EncryptDataRequestI,
  LoginI,
  SendSmsI,
  TraditionalLoginI,
} from '@/interfaces/auth.interface';

export class AuthService {
  private axiosService: AxiosInstance;

  constructor() {
    this.axiosService = new AxiosService().instance();
  }

  async login(payload: LoginI) {
    return this.axiosService.post('auth/validate-otp-sucursal', payload);
  }

  async logout() {
    return this.axiosService.post('auth/logout-sucursal', {});
  }

  async sendSms(payload: SendSmsI) {
    return this.axiosService.post('auth/login-sucursal', payload);
  }

  async validateTokenSucursal(token: string) {
    return this.axiosService.get(`auth/validate-token-sucursal?q=${token}`);
  }

  async authenticatePublicly(payload: TraditionalLoginI) {
    return this.axiosService.post('auth/provider/login', payload);
  }

  async authenticatePublicEncrypted(payload: EncryptDataRequestI) {
    return this.axiosService.post('auth/public/login', payload);
  }
}
