import { MutationTree } from 'vuex';
import { PaymentStateInterface } from './state';

const mutation: MutationTree<PaymentStateInterface> = {
  someMutation(/* state: PaymentStateInterface */) {
    // a line to prevent linter errors
  },
};

export default mutation;
