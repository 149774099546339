/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

/** Interface */
import { NegotiationWithinSimulatorI } from '@/interfaces/negotiation.interface';

export class PaymentPlanService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async negotiationsSave(payload: NegotiationWithinSimulatorI) {
    return this.service.post('api/v1/negotiations/save', {
      ...payload,
    });
  }
}
