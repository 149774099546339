import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

/** Utils */
import { verifyItemSession, verifyUserSession } from '@/utils/general-utils';

/** Layouts */
import LayoutApp from '@/layouts/LayoutApp.vue';
import LayoutAuth from '@/layouts/LayoutAuth.vue';
import LayoutMain from '@/layouts/LayoutMain.vue';
import LayoutCoupon from '@/layouts/LayoutCoupon.vue';
import LayoutPaymentStatus from '@/layouts/LayoutPaymentStatus.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: 'Login' */ '@/views/Login.vue'),
    meta: { layout: LayoutAuth, requiredAuth: false },
    props: (route) => ({ token: route.query.q }),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'Home' */ '@/views/Home.vue'),
    meta: { layout: LayoutApp, requiredAuth: true },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () =>
      import(
        /* webpackChunkName: 'Payments' */ '@/modules/payments/pages/Payments.vue'
      ),
    meta: { layout: LayoutMain, requiredAuth: true },
  },
  {
    path: '/redirect-payment/:token',
    name: 'RedirectPaymentLink',
    component: () =>
      import(
        /* webpackChunkName: 'RedirectPaymentLink' */ '@/views/RedirectPaymentLink.vue'
      ),
    meta: { layout: LayoutAuth, requiredAuth: false },
  },
  {
    path: '/payment',
    name: 'PaymentLink',
    component: () =>
      import(/* webpackChunkName: 'PaymentLink' */ '@/views/PaymentLink.vue'),
    meta: { layout: LayoutAuth, requiredAuth: false },
  },
  {
    path: '/coupon',
    name: 'CouponLink',
    component: () =>
      import(/* webpackChunkName: 'CouponLink' */ '@/views/CouponLink.vue'),
    meta: { layout: LayoutCoupon, requiredAuth: false },
  },
  {
    path: '/payment-status/:token',
    name: 'PaymentStatus',
    component: () =>
      import(
        /* webpackChunkName: 'PaymentStatus' */ '@/views/PaymentStatus.vue'
      ),
    meta: { layout: LayoutPaymentStatus, requiredAuth: false },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'Login',
    },
  },
];

if (process.env.VUE_APP_OFFLINE.toLowerCase() === 'true') {
  routes.splice(0, 5);
  routes.push({
    path: '/offline',
    name: 'Offline',
    component: () =>
      import(/* webpackChunkName: 'OfflinePage' */ '@/views/OfflinePage.vue'),
    meta: { layout: LayoutAuth, requiredAuth: false },
  });
  routes.push({
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'Offline',
    },
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn =
    verifyItemSession('token') &&
    verifyItemSession('user') &&
    verifyUserSession();

  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (isLoggedIn) {
      next();

      return;
    }

    next('/');
  } else {
    if (isLoggedIn) {
      if (to.path === '/') {
        next('/home');

        return;
      }
    }

    next();
  }
});

export default router;
