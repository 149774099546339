
import { computed, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

/**Utils */
import { getStorageDecrypted } from './utils/general-utils';
import { AuthUserTypes } from './utils/mutation-types';

export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore();
    const route = useRoute();

    const layout = computed(() => route.meta.layout);

    onMounted(() => {
      store.commit(AuthUserTypes.SET_AUTH_USER, getStorageDecrypted('user'));
    });

    return { layout };
  },
});
