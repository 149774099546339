import { useStore } from 'vuex';
import { ApmBase } from '@elastic/apm-rum';

export function useElasticTransactionApm() {
  const store = useStore();

  const customTransactioncApm = (
    apm: ApmBase,
    name: string,
    type: string,
    payload: unknown = undefined
  ): void => {
    store.dispatch('startTransactionApm', {
      apm,
      name,
      type,
      payload,
    });
  };

  const addTransaction = (response: unknown = undefined) => {
    store.dispatch('addTransaction', response);
  };

  const endTransactionApm = () => {
    store.dispatch('endTransactionApm');
  };

  return { customTransactioncApm, addTransaction, endTransactionApm };
}
