import { PlaceToPayRequestI } from '@/interfaces/placetopay.interface';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export class PayService {
  private axiosService: AxiosInstance;

  constructor() {
    this.axiosService = new AxiosService().instance();
  }

  async payFee(payload: PlaceToPayRequestI, token?: string) {
    const requestConfig: AxiosRequestConfig = Object.create({});

    if (token) {
      requestConfig.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return this.axiosService.post('api/payments/pay', payload, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getIp() {
    return fetch('https://1.1.1.1/cdn-cgi/trace')
      .then((res) => res.text())
      .catch(() => console.log);
  }

  async getCovenantsByPortfolio(type: string, id: string, token?: string) {
    const requestConfig: AxiosRequestConfig = {
      params: {
        id,
        type,
      },
    };

    if (token) {
      requestConfig.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return this.axiosService.get(
      'api/utilities/get-covenants-by-portfolio',
      requestConfig
    );
  }
}
