import { createStore } from 'vuex';

/** Services */
import { EncryptService } from '@/services/encrypt.service';
import { AuthService } from '@/services/auth.service';
import { PayService } from '@/services/pay.service';
import { PqrService } from '@/services/pqr.service';
import { SearchService } from '@/services/search.service';

/** Interfaces */
import { DatePay } from '@/interfaces/date-pay.interface';
import { ResultGeneralI } from '@/interfaces/search-information.interface';
import {
  EncryptDataAdditionalI,
  LocationI,
  LoginI,
  LoginResponseI,
  SendSmsI,
  UserLoginI,
} from '@/interfaces/auth.interface';
import { ResultSavePeaceI } from '@/interfaces/save-peace.interface';
import {
  AgreementsI,
  AttributesGeneralI,
  LastPaymentStatusI,
  LastPaymentStatusRequestI,
  LastPaymentStatusResponseI,
  LinceObligationI,
  NegotiationsI,
  ResponseGetGeneralI,
} from '@/interfaces/general.interface';
import {
  NegotiationWithinSimulatorI,
  SearchNegotiationI,
  SimulateNegotiationI,
} from '@/interfaces/negotiation.interface';
import { PqrDataI, StatusPqrI } from '@/interfaces/pqr.interface';

/** Mutation-types */
import {
  AuthUserTypes,
  HomeTypes,
  SearcherTypes,
  SavePeaceTypes,
  DateUpdateTypes,
  FinancialTipsTypes,
  DataGeneralTypes,
  PayTypes,
  DataNegotiationTypes,
  ShowFinancialTypes,
  PqrTypes,
  NegotiationsTypes,
  LocationTypes,
  ProductTypes,
  ObligationSelectedTypes,
  PaymentsTypes,
} from '@/utils/mutation-types';

import { ElasticTypes } from '@/utils/elastic-types';

/** Utils */
import {
  disableEncryption,
  getStorageDecrypted,
  removeStorage,
  renameObjectProperties,
  saveStorage,
} from '@/utils/general-utils';
import { NewService } from '@/services/general.service';
import { FinancialTipsService } from '@/services/financial-tips.service';
import { SimulatorService } from '@/services/simulator.service';
import {
  ResponseSaveNegotiationI,
  SavedNegotiationI,
} from '@/interfaces/accept-conditions.interface';
import { PaymentPlanService } from '@/services/negotiation.service';
import {
  PlaceToPayRequestI,
  PlaceToPayResponseI,
} from '@/interfaces/placetopay.interface';
import { TransactionApmI } from '@/interfaces/elastic-interface';

/** Modules */
import PaymentModule from '@/modules/payments/store';
import CouponModule from '@/modules/coupon/store';

export type State = {
  client: ResultGeneralI;
  linceId: string | null;
  currentHome: string;
  payments: string;
  savePeace: ResultSavePeaceI;
  authUser: UserLoginI;
  dateUpdate: DatePay;
  financialTips: string[];
  dataGeneral: AttributesGeneralI;
  dataLastPaymentStatus: LastPaymentStatusI[];
  dataNegotiation: SimulateNegotiationI[];
  payData: PlaceToPayResponseI;
  showFinancialTips: boolean;
  pqrStatus: StatusPqrI;
  negotiationSave: SavedNegotiationI[];
  location: LocationI;
  obligationSelected: AgreementsI | NegotiationsI | LinceObligationI;
  spanApm: any;
};

const mainState: State = {
  client: {},
  linceId: null,
  currentHome: 'Dashboard',
  payments: 'Payments',
  savePeace: {},
  dateUpdate: {},
  financialTips: [],
  authUser: getStorageDecrypted('user'),
  dataGeneral: Object.create({}),
  dataLastPaymentStatus: [],
  dataNegotiation: [],
  payData: Object.create({}),
  showFinancialTips: false,
  pqrStatus: Object.create({}),
  negotiationSave: [],
  location: Object.create({}),
  obligationSelected: Object.create({}),
  spanApm: null,
};

const store = createStore({
  state: mainState,
  mutations: {
    [SearcherTypes.SET_CLIENT](state, payload) {
      state.client = payload;
    },
    [ProductTypes.SET_LINCE_ID](state, payload) {
      state.linceId = payload;
    },
    [HomeTypes.SET_COMPONENT](state, payload) {
      state.currentHome = payload;
    },
    [PaymentsTypes.SET_PAYMENT](state, payload) {
      state.payments = payload;
    },
    [AuthUserTypes.SET_AUTH_USER](state, payload) {
      state.authUser = payload;
    },
    [SavePeaceTypes.SET_SAVE_PEACE](state, payload) {
      state.savePeace = payload;
    },
    [DateUpdateTypes.SET_DATE_UPDATE](state, payload) {
      state.dateUpdate = payload;
    },
    [FinancialTipsTypes.SET_FINANCIA_TIPS](state, payload) {
      state.financialTips = payload;
    },
    [DataGeneralTypes.SET_DATA_GENERAL](state, payload) {
      state.dataGeneral = payload;
    },
    [DataGeneralTypes.SET_DATA_LAST_PAYMENT_STATUS](state, payload) {
      state.dataLastPaymentStatus = payload;
    },
    [DataNegotiationTypes.SET_DATA_NEGOTIATION](state, payload) {
      state.dataNegotiation = payload;
    },
    [PayTypes.SET_PAY_DATA](state, payload) {
      state.payData = payload;
    },
    [ShowFinancialTypes.SET_SHOW_FINANCIA_TIPS](state, payload) {
      state.showFinancialTips = payload;
    },
    [PqrTypes.SET_STATUS_PQR](state, payload) {
      state.pqrStatus = payload;
    },
    [NegotiationsTypes.SET_NEGOTIATIONS_SAVE](state, payload) {
      state.negotiationSave = payload;
    },
    [LocationTypes.SET_LOCATION](state, payload) {
      state.location = payload;
    },
    [ObligationSelectedTypes.SET_OBLIGATION_SELECTED](state, payload) {
      state.obligationSelected = payload;
    },
    [ElasticTypes.SET_SPAN_APM](state, payload) {
      state.spanApm = payload;
    },
    [ElasticTypes.END_SPAN_APM](state) {
      state.spanApm?.end();
    },
  },
  actions: {
    async sendSms(_context, payload: SendSmsI) {
      const { data } = await new AuthService().sendSms(payload);
      return disableEncryption() ? data : EncryptService.decrypt(data);
    },

    async login({ dispatch }, payload: LoginI) {
      const { data } = await new AuthService().login(payload);
      await dispatch('storeUser', data);
    },

    async validateTokenSucursal({ dispatch }, token: string) {
      const { data } = await new AuthService().validateTokenSucursal(token);
      await dispatch('storeUser', data);
    },

    async storeUser({ commit, dispatch }, data: LoginResponseI | string) {
      const decrypted = disableEncryption()
        ? data
        : EncryptService.decrypt(data?.toString());
      const response = decrypted?.data;
      await dispatch('addTransaction', response);
      const user = response?.user || {};
      const fullName = `${user?.name} ${user?.lastname}`;
      const authUser = {
        full_name: fullName || '',
        ...user,
      };
      commit(AuthUserTypes.SET_AUTH_USER, authUser);
      saveStorage('token', response?.access_token || '');
      saveStorage(
        'user',
        EncryptService.encrypt(JSON.stringify(authUser), true)
      );
    },

    async getGeneral({ commit }) {
      const { data } = await new NewService().getGeneralService();
      const decrypted: ResponseGetGeneralI = disableEncryption()
        ? data
        : EncryptService.decrypt(data);
      const attributes = decrypted?.data?.attributes;
      const dataGeneral: AttributesGeneralI = {
        ...attributes,
        lince_obligations: attributes?.lince_obligations?.attributes,
        closed_obligations: attributes?.closed_obligations?.attributes,
        repository_obligations: attributes?.repository_obligations?.attributes,
      };
      commit(DataGeneralTypes.SET_DATA_GENERAL, dataGeneral);
      commit(ProductTypes.SET_LINCE_ID, attributes?.lince_id ?? null);

      return decrypted;
    },

    async getDataLastPaymentStatus(
      { commit },
      payload: LastPaymentStatusRequestI
    ) {
      const { data } = await new NewService().getDataLastPaymentStatus(payload);
      const decrypted: LastPaymentStatusResponseI = disableEncryption()
        ? data
        : EncryptService.decrypt(data);
      commit(
        DataGeneralTypes.SET_DATA_LAST_PAYMENT_STATUS,
        decrypted?.data?.attributes
      );

      return decrypted;
    },

    async createPQR({ commit }, payload: PqrDataI) {
      const { data } = await new PqrService().createPqr(payload);
      const decrypted = disableEncryption()
        ? data
        : EncryptService.decrypt(data);

      commit(PqrTypes.SET_STATUS_PQR, decrypted?.data);

      return decrypted?.data;
    },

    /**
     * [Eng] Get the simulator information.
     * [Spa] Obtenga la información del simulador.
     *
     * @param {*} { commit }
     * @param {SearchNegotiationI} payload
     */
    async getSimulation({ commit }, payload: SearchNegotiationI) {
      const { data } = await new SimulatorService().getSimulation(payload);
      const decrypted = disableEncryption()
        ? data
        : EncryptService.decrypt(data);
      commit(
        DataNegotiationTypes.SET_DATA_NEGOTIATION,
        decrypted?.data.attributes
      );
      return decrypted?.data?.attributes;
    },

    changeShowFinancialTips({ commit }, payload: boolean) {
      commit(ShowFinancialTypes.SET_SHOW_FINANCIA_TIPS, payload);
    },

    async getCommitees({ commit }) {
      try {
        const { data } = await new SearchService().getCommitees();
        const decrypted = disableEncryption()
          ? data
          : EncryptService.decrypt(data);
        commit(SearcherTypes.SET_COMMITTEES, decrypted?.data[0]);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * [Eng] Trades are saved for the selected portfolio.
     * [Spa] Se guarda la negociacion para el portafolio seleccionado.
     *
     * @param {*} { commit }
     * @param {LoginI} payload
     */
    async negotiationsSave({ commit }, payload: NegotiationWithinSimulatorI) {
      const { data } = await new PaymentPlanService().negotiationsSave(payload);
      const decrypted: ResponseSaveNegotiationI = disableEncryption()
        ? data
        : EncryptService.decrypt(data);
      commit(
        NegotiationsTypes.SET_NEGOTIATIONS_SAVE,
        decrypted?.data?.attributes
      );
      return decrypted;
    },

    async getFinancialTips({ commit }) {
      try {
        const { data } = await new FinancialTipsService().getFinancialTips();
        commit(FinancialTipsTypes.SET_FINANCIA_TIPS, data?.data);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * [Eng] Clear state.
     * [Spa] Limpia los estados.
     *
     * @param {*} { commit }
     * @param {LoginI} payload
     */
    async clearState({ commit }, manage = false) {
      if (!manage) {
        commit(DataGeneralTypes.SET_DATA_GENERAL, {});
        commit(SearcherTypes.SET_CLIENT, {});
        commit(HomeTypes.SET_COMPONENT, 'Dashboard');
        commit(AuthUserTypes.SET_AUTH_USER, {});
      }
      commit(SavePeaceTypes.SET_SAVE_PEACE, {});
      commit(DateUpdateTypes.SET_DATE_UPDATE, {});
      commit(FinancialTipsTypes.SET_FINANCIA_TIPS, {});
      commit(DataNegotiationTypes.SET_DATA_NEGOTIATION, []);
      commit(PayTypes.SET_PAY_DATA, {});
      commit(ShowFinancialTypes.SET_SHOW_FINANCIA_TIPS, false);
      commit(PqrTypes.SET_STATUS_PQR, {});
      commit(NegotiationsTypes.SET_NEGOTIATIONS_SAVE, []);
      commit(LocationTypes.SET_LOCATION, {});
    },

    /**
     * Cierre de sesión
     *
     * @param {*} { dispath }
     */
    async logout({ dispatch }) {
      await dispatch('clearState');
      removeStorage('token');
      removeStorage('user');
    },

    async payFee({ commit }, payload: PlaceToPayRequestI) {
      const { data } = await new PayService().payFee(payload);

      const decrypted = disableEncryption()
        ? data
        : EncryptService.decrypt(data);
      commit(PayTypes.SET_PAY_DATA, decrypted?.data);

      return decrypted?.data;
    },

    async getIp({ commit }) {
      const res: string = (await new PayService().getIp()) as string;

      const data = res
        .trim()
        .split('\n')
        // eslint-disable-next-line @typescript-eslint/ban-types
        .reduce((previous: object, current: string) => {
          const split = current.split('=');
          Object.assign(previous, { [split[0]]: split[1] });
          return previous;
        }, Object.create({}));

      commit(LocationTypes.SET_LOCATION, data);
    },

    /**
     * [Eng].
     * [Spa].
     *
     * @param param0
     * @param data
     */
    async startTransactionApm({ commit, getters }, data: TransactionApmI) {
      console.info('ejecutando...');
      const { apm, name, type, options, payload } = data;
      if (payload) {
        const request = payload as Record<string, string>;
        const labels = renameObjectProperties(request, 'request');
        apm.addLabels({
          ...labels,
          user_name: getters.getAuthUser?.name,
          user_identification: getters.getAuthUser?.identification,
          user_clientId: getters.getAuthUser?.client_id,
          user_linceId: getters.getAuthUser?.lince_id,
        });
        const span = apm?.startTransaction(name, type, options);
        commit(ElasticTypes.SET_SPAN_APM, span);
      }
    },

    async addTransaction({ state }, payload: any) {
      if (payload) {
        const request = payload as Record<string, string>;
        const labels = renameObjectProperties(request, 'response');
        state.spanApm.addLabels({
          ...labels,
        });
      }
    },

    /**
     * [Eng].
     * [Spa].
     *
     * @param param0
     * @param data
     */
    async endTransactionApm({ commit }) {
      console.info('cerrando...');
      commit(ElasticTypes.END_SPAN_APM);
    },

    /**
     * [Eng] Get the session token for requests in utilities.
     * [Spa] Obtener el token de sesión para peticiones en servicios públicos.
     *
     * @param   {ActionContext} _context
     * @param   {EncryptDataAdditionalI}  payload
     */
    async getPublicToken(_context, payload: EncryptDataAdditionalI) {
      try {
        const params = {
          username: process.env.VUE_APP_USER_PUBLIC,
          password: process.env.VUE_APP_PASS_PUBLIC,
          date: payload.date,
          duration: payload.duration,
        };

        const request = {
          data: EncryptService.encrypt(JSON.stringify(params), true),
        };

        const { data } = await new AuthService().authenticatePublicEncrypted(
          request
        );

        return disableEncryption() ? data : EncryptService.decrypt(data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    getAuthUser(state) {
      return state.authUser;
    },
    getDataGeneral(state) {
      return state.dataGeneral;
    },
    getIdRefinancia(state) {
      return state.client?._id;
    },
    getInfoClient(state) {
      return state.client._source?.attributes;
    },
    getTimestamp(): Date {
      return new Date();
    },
    getSavePeace(state) {
      return state.savePeace?.data;
    },
    getDateUpdate(state) {
      return state.dateUpdate?.data;
    },
    getFinancialTips(state) {
      return state.financialTips;
    },
    getPortfolios(state) {
      const data = state.dataGeneral;
      return data?.portfolios && data.portfolios.length > 0
        ? data.portfolios
        : [];
    },
    getLocation(state) {
      return state.location;
    },
    getLinceId(state) {
      return state.linceId;
    },
    getObligationSelected(state) {
      return state.obligationSelected;
    },
    getLinceObligations(state) {
      return state?.dataGeneral?.lince_obligations || [];
    },
    getDannObligations(state) {
      return state?.dataGeneral?.repository_obligations || [];
    },
    getCloseObligations(state) {
      return state.dataGeneral?.closed_obligations;
    },
  },
  modules: {
    payment: PaymentModule,
    coupon: CouponModule,
  },
});

export default store;
