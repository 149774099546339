export enum MultiPdfTypes {
  // Paz y salvos
  PAZ_Y_SALVO = '1',
  // Aval de Lince en mora
  AVAL_EN_MORA = '2',
  // Aval de Lince al día
  AVAL_AL_DIA = '3',
  // Acuerdo refinancia al día
  ACUERDO_REFINANCIA_AL_DIA = '4',
  // Acuerdo refinancia en enMora
  ACUERDO_REFINANCIA_EN_MORA = '5',
  // Al día
  AL_DIA = '8',
  // En mora
  EN_MORA = '9',
  // Paz y salvo
  PAZ_Y_SALVO_DANN_REGIONAL = '10',
}

export enum ObligationTypes {
  NEGOTIATION = 'negotiation',
  AGREEMENT = 'agreement',
  AVAL_LINCE = 'aval_lince',
  AVAL_REPOSITORY = 'aval_repository',
}

export enum AnchorTypes {
  DANN = 1,
  BCS = 2,
}
