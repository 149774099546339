
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'FinancialTips',
  setup() {
    const store = useStore();
    const contTips = ref(0);

    const timestamp = computed(() => {
      const dateUpdate = store.getters.getTimestamp;
      return dateUpdate ? new Date(dateUpdate).toLocaleDateString() : '';
    });

    const getTips = computed(() => {
      return store.state.financialTips;
    });

    return {
      contTips,
      getTips,
      timestamp,
    };
  },
});
