/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LastPaymentStatusRequestI } from '@/interfaces/general.interface';
import { LincePseRequestI } from '@/interfaces/lince.interface';
import { DataFeesSummary } from '@/interfaces/payPlan.interface';
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

export class NewService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async getGeneralService() {
    return this.service.get('api/v1/clients/branch-search');
  }

  async getFeesSummary(payload: DataFeesSummary) {
    return this.service.post('api/v1/obligation/get-fees-summary', payload);
  }

  async getFilterPseObligation(payload: LincePseRequestI) {
    return this.service.post(
      'api/v1/obligation/get-filter-pse-obligations',
      payload
    );
  }

  async getDataLastPaymentStatus(payload: LastPaymentStatusRequestI) {
    return this.service.post(
      'api/v1/payments/get-last-payment-status',
      payload
    );
  }
}
