
import { defineAsyncComponent, defineComponent, PropType } from 'vue';
import { ComponentHomeTypes } from '@/utils/general-utils';
import { HomeTypes } from '@/utils/mutation-types';

/* Interfaces */
import { UserLoginI } from '@/interfaces/auth.interface';

export default defineComponent({
  name: 'Sidebar',
  props: {
    userData: {
      type: Object as PropType<UserLoginI>,
    },
  },
  components: {
    ModalCustomerServiceChannels: defineAsyncComponent(
      () => import('@/components/help/ModalCustomerServiceChannels.vue')
    ),
  },
  emits: ['close-sidebar'],
  data() {
    return {
      showModalHelp: false,
      showMPayd: false as boolean,
      visiblePicker: false,
      dontShowPicker: false,
      hideSide: true,
      showModal: false,
      showModalCertificate: false,
      PayDate: new Date(),
    };
  },
  methods: {
    openModalHelp() {
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - canales-de-atencion',
        type: 'custom',
        payload: {},
      });
      this.showModalHelp = true;
      this.closeSidebar();
      this.$store.dispatch('endTransactionApm');
    },

    evntModalCertificate() {
      this.showModalCertificate = true;
    },

    evntCloseCertificate() {
      this.showModalCertificate = false;
    },

    openModalPay() {
      this.showMPayd = true;
    },

    closeModal() {
      this.showModalHelp = false;
      this.showMPayd = false;
      this.visiblePicker = false;
    },

    showPicker(emitDatePay: string) {
      this.PayDate = new Date(emitDatePay);
      this.visiblePicker = true;
    },

    changeComponent() {
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - inicio',
        type: 'custom',
        payload: {},
      });
      this.$store.dispatch('changeShowFinancialTips', false);
      this.$store.dispatch('endTransactionApm');
      const dashboardComponent: string = ComponentHomeTypes.DASHBOARD;
      const currentComponent: string = this.$store.state.currentHome;
      if (currentComponent !== dashboardComponent) {
        this.$store.commit(HomeTypes.SET_COMPONENT, dashboardComponent);
      }
      this.closeSidebar();
    },

    logout() {
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - salida-segura',
        type: 'custom',
        payload: {},
      });
      this.$store
        .dispatch('logout')
        .then(() =>
          window.location.replace(
            process.env.VUE_APP_LOGIN_REDIRECT || window.location.origin
          )
        )
        .catch(console.log)
        .finally(() => this.$store.dispatch('endTransactionApm'));
    },

    closeModalSP() {
      this.showModal = false;
    },

    goToFinantialTips() {
      this.$store.dispatch('changeShowFinancialTips', true);
      this.closeSidebar();
    },

    closeSidebar() {
      this.$emit('close-sidebar');
    },
  },
  computed: {
    isLinceUser() {
      return this.$store.getters?.getAuthUser?.lince_id;
    },
  },
});
