import Swal, { SweetAlertResult } from 'sweetalert2';

/**
 * Variable de Configuración del modal
 */
const swalOptions = {
  allowOutsideClick: false,
  allowEscapeKey: false,
  confirmButtonText: 'ACEPTAR',
  cancelButtonText: 'CERRAR',
  confirmButtonColor: '#A3053D',
  cancelButtonColor: '#D7D7D7',
};

/**
 * Modal de carga Loading.
 */
function showSwalLoading(): void {
  Swal.fire({
    text: 'Espere un momento ...',
    showConfirmButton: false,
    ...swalOptions,
    willOpen: () => {
      Swal.showLoading();
    },
  });
}

/**
 * Cuadro de diálogo
 *
 * @param {string} title
 * @param {string} html
 * @return {*} Modal
 */
function showSwalDialog(
  title: string,
  html: string
): Promise<SweetAlertResult<unknown>> {
  return Swal.fire({
    title,
    html,
    icon: 'question',
    ...swalOptions,
    showCancelButton: true,
  });
}

/**
 * Modal de respuesta errónea
 *
 * @param {string} html
 * @return {*} Modal
 */
function showSwalError(html: string): Promise<SweetAlertResult<unknown>> {
  return Swal.fire({
    title: 'Lo Sentimos',
    html,
    icon: 'error',
    ...swalOptions,
  });
}

/**
 * Modal de respuesta exitosa
 *
 * @param {string} html
 * @return {*} Modal
 */
function showSwalInfo(html: string): Promise<SweetAlertResult<unknown>> {
  return Swal.fire({
    title: '¡Atención!',
    html,
    icon: 'info',
    ...swalOptions,
  });
}

/**
 * Modal de respuesta exitosa
 *
 * @param {string} html
 * @return {*} Modal
 */
function showSwalSuccess(html: string): Promise<SweetAlertResult<unknown>> {
  return Swal.fire({
    title: '¡Perfecto!',
    html,
    icon: 'success',
    ...swalOptions,
  });
}

/**
 * Cerrar el modal
 */
function closeSwal(): void {
  Swal.close();
}

export {
  showSwalLoading,
  showSwalDialog,
  showSwalError,
  showSwalInfo,
  showSwalSuccess,
  closeSwal,
};
