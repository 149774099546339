import useStore from '@/store/index';
import { AxiosError } from 'axios';

/**Services */
import { EncryptService } from '@/services/encrypt.service';

/**Utils */
import { showSwalError, showSwalInfo } from './sweetalert2-utils';

/**bookstore days  */
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
dayjs.extend(customParseFormat);

export function currency(value: string | number | null | undefined): string {
  let number = 0;
  if (value) {
    number = +value;
  }
  return new Intl.NumberFormat('es-CO', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'COP',
  }).format(number);
}

/** Desencripta de informacion del usuario */
export function getStorageDecrypted(key: string): any {
  try {
    const item = localStorage.getItem(key);
    if (!item) {
      return;
    }
    return EncryptService.decrypt(String(item), true);
  } catch (e) {
    console.log((e as Error).message);
  }
}

export function verifyUserSession(): boolean {
  try {
    const user = getStorageDecrypted('user');
    const keys = [
      'client_id',
      'client_status',
      'commercial_status',
      // "country",
      'document_type',
      'identification',
      'name',
      'photo',
      'profile_client',
    ];
    for (const key of keys) {
      if (!Object.prototype.hasOwnProperty.call(user, key)) {
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
}

export function disableEncryption(): boolean {
  const debug: string = process.env.VUE_APP_DISABLE_ENCRYPTION;
  return debug.toLowerCase() === 'true';
}

export function verifyItemSession(value: string): any {
  const item = localStorage.getItem(value)?.toString().toLowerCase();
  return item && item !== 'undefined' && item !== '' && item !== 'null';
}

export function saveStorage(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function getStorage(key: string, stringify = false): string | null {
  const item = localStorage.getItem(key);
  if (stringify) {
    return JSON.parse(item || '{}');
  }
  return item;
}

export function removeStorage(key: string): void {
  localStorage.removeItem(key);
}

export function showErrors(
  error: AxiosError,
  message: string,
  login = false
): void {
  if (!error.response) {
    showSwalError('Error al realizar la petición, inténtelo nuevamente');
  } else {
    const data = disableEncryption()
      ? error.response.data
      : EncryptService.decrypt(error.response.data);
    if (error.response.status === 401) {
      showSwalError('Su sessión ha expirado').then(() => {
        useStore
          .dispatch('logout')
          .then(() => {
            window.location.replace(
              process.env.VUE_APP_LOGIN_REDIRECT || window.location.origin
            );
          })
          .catch(console.error);
      });
    } else if (error.response.status === 422) {
      const errors = Object.values(data.error);
      const content = errors.map((item) => `<li>${item}</li>`).join('');
      if (login) {
        showSwalError(`<ul>${content}</ul>`);
      } else {
        showSwalError(
          `<h3><strong>¡Por favor complete los campos!</strong></h3><br /><ul>${content}</ul>`
        );
      }
    } else if (error.response.status === 413) {
      showSwalError('El archivo adjunto es demasiado grande.');
    } else if (
      data.message &&
      data.message.includes('index_not_found_exception')
    ) {
      showSwalInfo(
        'En este momento estamos actualizando la información de sus obligaciones, por favor consulte más tarde.'
      );
    } else {
      showSwalError(message);
    }
  }
}

/**
 * Convertir una respuesta array buffer a objeto
 *
 * @export
 * @template T
 * @param {ArrayBuffer} ab
 * @returns {(T | undefined)}
 */
export function arrayBufferToObject<T>(ab: ArrayBuffer): T | undefined {
  try {
    const text = String.fromCharCode.apply(
      null,
      Array.from(new Uint8Array(ab))
    );
    if (!text) return undefined;
    return JSON.parse(text) as T;
  } catch (error) {
    return undefined;
  }
}

/**
 * Convertir archivo de base64 a archivo binario
 *
 * @param {string} data
 * @param {string} filename
 * @param {string} contentType
 * @return {File}
 */
export function base64ToFile(
  data: string,
  filename: string,
  contentType: string
): File {
  const sliceSize = 512;
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, filename, { type: contentType });
}

export enum ComponentHomeTypes {
  DASHBOARD = 'Dashboard',
}

export function hiddenCharts(data: string): string {
  return `${'*'.repeat(7)}${data.substr(-3)}`;
}

/**
 * Dar formato a una fecha con la opción de cambiar el separador
 *
 * @param {string} date
 * @returns {string}
 */
export function formatDate(
  date: string | number | Date | dayjs.Dayjs | null | undefined
): string {
  const invalidDate = 'Fecha no válida';

  if (date) {
    const format = 'DD/MM/YYYY';
    const newDate = dayjs(date);
    const newCustomDate = dayjs(date, ['DD/MM/YYYY', 'DD-MM-YYYY']);

    if (newDate.isValid()) {
      return newDate.format(format);
    }

    if (newCustomDate.isValid()) {
      return newCustomDate.format(format);
    }

    return invalidDate;
  }

  return invalidDate;
}

export function redirectWhatsappAdviser(phone: string, text: string): void {
  const url = `https://api.whatsapp.com/send?phone=${phone}&text=${text}`;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export function isAppleDevice(ref: HTMLLinkElement, link: string): void {
  if (window.navigator.vendor === 'Apple Computer, Inc.') {
    ref.href = link;
    ref.click();
  } else {
    window.open(link, '_self');
  }
}

export function handleCatchErrorCustom(
  error: AxiosError,
  defaultMsg: string
): void {
  if (!error.response) {
    showSwalError('Error al realizar la petición, inténtelo nuevamente');
  } else {
    const data = disableEncryption()
      ? error.response.data
      : EncryptService.decrypt(error.response.data);

    const apiError = data.error || data.message || '';
    const codeError = data.code || 500;

    if (codeError === 401) {
      showSwalError('Su sessión ha expirado').then(() => {
        useStore
          .dispatch('logout')
          .then(() => window.location.replace(window.location.origin));
      });
    } else if (codeError === 422) {
      const errors = Object.values(apiError);
      const content = errors.map((item) => `<li>${item}</li>`).join('');
      showSwalError(
        `<h3><strong>¡Por favor complete los campos!</strong></h3><br /><ul>${content}</ul>`
      );
    } else {
      const msg = apiError.startsWith('*') ? apiError : defaultMsg;
      showSwalError(msg);
    }
  }
}

export function addMinutesToDate(minutes: number): string {
  const now = dayjs(new Date());
  return now.add(minutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
}

export function renameObjectProperties(
  payload: any,
  prefix: string
): Record<string, string> {
  return Object.keys(payload).reduce((group: any, row: string) => {
    group[`${prefix}_${row}`] = group[`${prefix}_${row}`] ?? payload[row];
    return group;
  }, {});
}
