/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Hex from 'crypto-js/enc-hex';
import AEX from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

/** Utils */
import { disableEncryption } from '@/utils/general-utils';

export class EncryptService {
  /**
   * Metodo para encriptar
   *
   * @static
   * @param {string} value
   * @returns
   * @memberof EncryptService
   */
  static encrypt(value: string, ignoreDisableEncryption = false) {
    if (disableEncryption() && !ignoreDisableEncryption) {
      return value;
    }
    value = JSON.parse(value);
    const text = JSON.stringify(value).replace(/\\"/gi, "'");
    const keyEncr = Hex.parse(process.env.VUE_APP_SECRET_TIME_KEY);
    const iv = Hex.parse(process.env.VUE_APP_SECRET_TIME_IV);
    return AEX.encrypt(text, keyEncr, { iv: iv })
      .toString()
      .replace(/\+/gi, '-')
      .replace(/\//gi, '_');
  }

  /**
   * Metodo para desencriptar
   *
   * @static
   * @param {string} data
   * @param {boolean} ignoreDisableEncryption
   * @returns
   * @memberof EncryptService
   */
  static decrypt(data: string, ignoreDisableEncryption = false) {
    try {
      if (disableEncryption() && !ignoreDisableEncryption) {
        return data;
      }
      const keyEncr = Hex.parse(process.env.VUE_APP_SECRET_TIME_KEY);
      const iv = Hex.parse(process.env.VUE_APP_SECRET_TIME_IV);

      data = data.replace(/-/gi, '+');
      data = data.replace(/_/gi, '/');
      data = data.replace(/%3D/gi, '=');

      const decrypted = AEX.decrypt(data, keyEncr, { iv: iv });

      return JSON.parse(decrypted.toString(Utf8));
    } catch (e) {
      return (e as Error).message;
    }
  }
}
