import { GetterTree } from 'vuex';
import { CouponStateInterface } from './state';
import { State as StateInterface } from '@/store/index';

const getters: GetterTree<CouponStateInterface, StateInterface> = {
  // someGetter(/* state */) {
  //   // return true;
  // },
};

export default getters;
