export enum SearcherTypes {
  SET_CLIENT = 'setClient',
  SET_COMMITTEES = 'setCommittees',
}

export enum HomeTypes {
  SET_COMPONENT = 'setComponentHome',
}

export enum PaymentsTypes {
  SET_PAYMENT = 'setPayment',
}

export enum PortfolioTypes {
  SET_PORTFOLIOS = 'setPortfolios',
}

export enum DateUpdateTypes {
  SET_DATE_UPDATE = 'setDateUpdate',
}

export enum PaymentPlanTypes {
  SET_PAYMENT_PLAN = 'setPaymentPlan',
}

export enum AuthUserTypes {
  SET_AUTH_USER = 'setAuthUser',
}

export enum SavePeaceTypes {
  SET_SAVE_PEACE = 'setSavePeace',
}

export enum FinancialTipsTypes {
  SET_FINANCIA_TIPS = 'setFinacialTips',
}

export enum DataGeneralTypes {
  SET_DATA_GENERAL = 'setDataGeneral',
  SET_DATA_LAST_PAYMENT_STATUS = 'setLastPaymentStatus',
}

export enum DataNegotiationTypes {
  SET_DATA_NEGOTIATION = 'setNegotiation',
}

export enum PayTypes {
  SET_PAY_DATA = 'setPayData',
}

export enum ShowFinancialTypes {
  SET_SHOW_FINANCIA_TIPS = 'setShowFinancialTips',
}

export enum PqrTypes {
  SET_STATUS_PQR = 'setPqrTypes',
}

export enum NegotiationsTypes {
  SET_NEGOTIATIONS_SAVE = 'setNegotiationSave',
}

export enum LocationTypes {
  SET_LOCATION = 'setLocation',
}

export enum ProductTypes {
  SET_LINCE_ID = 'setLinceId',
}

export enum ObligationSelectedTypes {
  SET_OBLIGATION_SELECTED = 'setObligationSelected',
}
