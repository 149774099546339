/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PqrDataI } from '@/interfaces/pqr.interface';
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

export class PqrService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async createPqr(payload: PqrDataI) {
    return this.service.post('/api/v1/pqrs', payload);
  }
}
