import { MutationTree } from 'vuex';
import { CouponStateInterface } from './state';

const mutation: MutationTree<CouponStateInterface> = {
  // someMutation(/* state: ExampleStateInterface */) {
  //   // a line to prevent linter errors
  // },
};

export default mutation;
