
import { computed, defineAsyncComponent, defineComponent, inject } from 'vue';
import { useStore } from 'vuex';

/** Interfaces */
import {
  ClosedObligationI,
  ResponseDownloadPdfI,
} from '@/interfaces/general.interface';

/** Services */
import { EncryptService } from '@/services/encrypt.service';
import { RequestDocumentService } from '@/services/request-document.service';

/** Utilidades */
import {
  base64ToFile,
  disableEncryption,
  showErrors,
} from '@/utils/general-utils';
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import { ApmBase } from '@elastic/apm-rum';
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'ClosedObligations',
  components: {
    ClosedObligationsItem: defineAsyncComponent(
      () => import('@/components/home/ClosedObligationsItem.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, endTransactionApm } =
      useElasticTransactionApm();

    const closedObligations = computed((): ClosedObligationI[] => {
      return store.state.dataGeneral?.closed_obligations || [];
    });

    const existClosedObligations = computed(() => {
      return closedObligations.value.length > 0;
    });

    const downloadDocument = (obligation: ClosedObligationI) => {
      customTransactioncApm(
        $apm,
        'click - generar-paz-y-salvo',
        'custom',
        obligation
      );
      showSwalLoading();
      new RequestDocumentService()
        .downloadPdf(obligation)
        .then((response) => {
          closeSwal();
          const { data } = response;
          const res: ResponseDownloadPdfI = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const { success, message, attributes } = res.data;
          store.dispatch('addTransaction', res.data);
          if (success) {
            const file: File = base64ToFile(
              attributes,
              `Paz-y-salvo-${obligation.id}.pdf`,
              'application/pdf'
            );
            const newBlob = new Blob([file], {
              type: 'application/pdf',
            });
            const windowUrl = window.URL || window.webkitURL;
            const url = windowUrl.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Paz-y-salvo-${obligation.id}.pdf`);
            link.click();
            setTimeout(() => {
              windowUrl.revokeObjectURL(url);
            }, 1000);
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al descargar el paz y salvo, por favor intentelo de nuevo.'
          );
        })
        .finally(() => endTransactionApm());
    };

    return {
      closedObligations,
      downloadDocument,
      existClosedObligations,
    };
  },
});
