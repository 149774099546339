import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';

/** Utils */
import { currency, formatDate } from '@/utils/general-utils';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueClipboard);
app.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: 'sucursal-virtual',
    serverUrl: process.env.VUE_APP_APM_ENDPOINT,
    secretToken: process.env.VUE_APP_APM_SECRET,
    logLevel: 'debug',
  },
});
app.mount('#app');

app.provide('$apm', app.config.globalProperties.$apm);

app.config.globalProperties.$filters = {
  currency,
  formatDate,
};
