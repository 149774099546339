export interface CouponStateInterface {
  prop: boolean;
}

function state(): CouponStateInterface {
  return {
    prop: true,
  };
}

export default state;
