/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

/** Interface */
import { SearchNegotiationI } from '@/interfaces/negotiation.interface';

export class SimulatorService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async getSimulation(payload: SearchNegotiationI) {
    return this.service.get('api/v1/committees/get-values-simulator-sucursal', {
      params: payload,
    });
  }
}
