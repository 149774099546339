import { AxiosService } from '@/services/axios.service';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  DataPaymentsSummary,
  ProcessPaymentParamsI,
  TransactionStatusParamsI,
} from '../interfaces/payments.interfaces';

export class PaymentService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async getPaymentsSummary(payload: DataPaymentsSummary) {
    return this.service.post('api/v1/obligation/get-fees-summary', payload);
  }

  async getProcessPayment(payload: ProcessPaymentParamsI) {
    return this.service.get(`api/v1/payments/get-payments-process/${payload}`);
  }

  async getResumePay(payload: TransactionStatusParamsI) {
    const requestConfig: AxiosRequestConfig = {};

    if (payload.token) {
      requestConfig.headers = {
        Authorization: `Bearer ${payload.token}`,
      };
    }

    return this.service.get(
      `api/payments/resume-pay/${payload.transactionId}`,
      requestConfig
    );
  }
}
