import { GetterTree } from 'vuex';
import { PaymentStateInterface } from './state';
import { State as StateInterface } from '@/store/index';

const getters: GetterTree<PaymentStateInterface, StateInterface> = {
  someGetter(/* state */) {
    // return true;
  },
};

export default getters;
