/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios';

/** Services */
import { AxiosService } from '@/services/axios.service';

/** Interfaces */
import {
  ClosedObligationI,
  ObligationWithoutAgreementParamsI,
} from '@/interfaces/general.interface';
import { DocumentRequestI } from '@/interfaces/request-document.interface';
import { LincePseRequestI } from '@/interfaces/lince.interface';

export class RequestDocumentService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async requestDocument(payload: DocumentRequestI) {
    return this.service.post('/api/v1/document-generator/request', payload);
  }

  async downloadPdf(
    payload:
      | ClosedObligationI
      | ObligationWithoutAgreementParamsI
      | LincePseRequestI
  ) {
    return this.service.post('api/v1/pdf/generate-pdf', payload);
  }
}
