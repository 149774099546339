/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosService } from '@/services/axios.service';
import { AxiosInstance } from 'axios';

/** Interface */
export class SearchService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().mock();
  }

  async getClients() {
    return this.service.get('client.json');
  }

  async getCommitees() {
    return this.service.get('committee.json');
  }
}
