
import { defineComponent } from 'vue';

/** Components */
import Sidebar from '@/components/layout/Sidebar.vue';

export default defineComponent({
  name: 'LayoutMain',
  components: {
    Sidebar,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.authUser;
    },
  },

  created() {
    this.$store.dispatch('getIp');
    this.$store.dispatch('getFinancialTips');
  },
});
