import axios, { AxiosInstance } from 'axios';

/** Utils */
import { getStorage } from '@/utils/general-utils';

export class AxiosService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
  }

  instance(): AxiosInstance {
    return axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${getStorage('token')}`,
      },
    });
  }

  mock(): AxiosInstance {
    return axios.create({
      baseURL: '',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${getStorage('token')}`,
      },
    });
  }
}
