import { ActionTree } from 'vuex';
import { PaymentStateInterface } from './state';
import { State as StateInterface } from '@/store/index';

/* Services **/
import { PaymentService } from '@/modules/payments/services/payments.service';

/* Interfaces **/
import {
  DataPaymentsSummary,
  ProcessPaymentParamsI,
  ResponsePaymentGeneralI,
  ResponseProcessPaymentI,
  ResponseTransactionStatusPlaceToPayI,
  TransactionStatusParamsI,
} from '@/modules/payments/interfaces/payments.interfaces';
import { disableEncryption } from '@/utils/general-utils';
import { EncryptService } from '@/services/encrypt.service';

const actions: ActionTree<PaymentStateInterface, StateInterface> = {
  async getPaymentsSummary({ commit }, payload: DataPaymentsSummary) {
    const { data } = await new PaymentService().getPaymentsSummary(payload);
    const decrypted: ResponsePaymentGeneralI = disableEncryption()
      ? data
      : EncryptService.decrypt(data);

    const response = decrypted?.data?.attributes;

    return response;
  },

  async getProcessPayments({ commit }, payload: ProcessPaymentParamsI) {
    const { data } = await new PaymentService().getProcessPayment(payload);

    const decrypted: ResponseProcessPaymentI = disableEncryption()
      ? data
      : EncryptService.decrypt(data);

    const response = decrypted?.data?.attributes;

    return response;
  },

  async getTransactionStatus({ commit }, payload: TransactionStatusParamsI) {
    const { data } = await new PaymentService().getResumePay(payload);

    const decrypted: ResponseTransactionStatusPlaceToPayI = disableEncryption()
      ? data
      : EncryptService.decrypt(data);

    const response = decrypted?.data?.attributes;

    return response;
  },
};

export default actions;
