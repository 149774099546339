export interface PaymentStateInterface {
  prop: boolean;
}

function state(): PaymentStateInterface {
  return {
    prop: true,
  };
}

export default state;
