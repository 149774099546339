
import { defineComponent } from 'vue';

/** Components */
import Sidebar from '@/components/layout/Sidebar.vue';
import MainView from '@/components/home/MainView.vue';
import FinancialTips from '@/components/home/FinancialTips.vue';
import ClosedObligations from '@/components/home/ClosedObligations.vue';
import FinancialTipsCarousel from '@/components/home/FinancialTipsCarousel.vue';

/** Interfaces */
import {
  AgreementsI,
  LinceObligationI,
  NegotiationsI,
  RepositoryObligationI,
  ResponseGetGeneralI,
} from '@/interfaces/general.interface';

/** Utils */
import { showErrors } from '@/utils/general-utils';
import { showSwalError } from '@/utils/sweetalert2-utils';

export default defineComponent({
  name: 'LayoutApp',
  components: {
    Sidebar,
    MainView,
    ClosedObligations,
    FinancialTipsCarousel,
    FinancialTips,
  },
  data() {
    return {
      isActive: false,
      isLoadingObligations: false,
      isLoadingPaymentStatus: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.authUser;
    },

    showFinancialTips() {
      return this.$store.state.showFinancialTips;
    },

    portfolios_() {
      const portfolios = this.$store.state.dataGeneral?.portfolios || [];
      return portfolios.length > 0 ? portfolios : [];
    },

    getAgreements() {
      const agreements: AgreementsI[] =
        this.$store.state.dataGeneral?.agreements || [];
      return agreements.length > 0 ? agreements : [];
    },

    getNegotiations() {
      const agreements: NegotiationsI[] =
        this.$store.state.dataGeneral?.negotiations || [];
      return agreements.length > 0 ? agreements : [];
    },

    getLinceObligations() {
      const obligations: LinceObligationI[] =
        this.$store.state.dataGeneral?.lince_obligations || [];
      return obligations.length > 0 ? obligations : [];
    },

    getRepositoryObligations() {
      const obligations: RepositoryObligationI[] =
        this.$store.state.dataGeneral?.repository_obligations || [];
      return obligations.length > 0 ? obligations : [];
    },
  },
  methods: {
    fetchDataGenaral() {
      this.isLoadingObligations = true;
      this.$store
        .dispatch('getGeneral')
        .then((response: ResponseGetGeneralI) => {
          const {
            success,
            attributes: {
              lince_obligations: linceObligations,
              closed_obligations: closedObligations,
            },
          } = response.data;
          if (success) {
            const messages: string[] = [];
            if (!linceObligations.success) {
              messages.push(
                'Se produjo un error al obtener la información de los avales.'
              );
            }
            if (!closedObligations.success) {
              messages.push(
                'Se produjo un error al obtener la información de las obligaciones cerradas.'
              );
            }
            if (messages.length > 0) {
              showSwalError(messages.map((item) => `<p>${item}</p>`).join(''));
            }

            this.fetchDataLastPaymentStatus();
          } else {
            showSwalError(
              'Se produjo un error al obtener la información general.'
            );
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al obtener la información, por favor intentelo de nuevo.'
          );
        })
        .finally(() => (this.isLoadingObligations = false));
    },
    fetchDataLastPaymentStatus() {
      const references: string[] = [
        ...this.getAgreements.map(
          (item) => item.payment_information[0].reference
        ),
        ...this.getNegotiations.map(
          (item) => item.payment_information[0].reference
        ),
        ...this.getLinceObligations.map(
          (item) => item.payment_information[0].reference
        ),
        ...this.getRepositoryObligations.map(
          (item) => item.payment_information[0].reference
        ),
      ];

      if (references.length === 0) {
        return;
      }

      this.isLoadingPaymentStatus = true;

      this.$store
        .dispatch('getDataLastPaymentStatus', { references })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al obtener el estado del último pago para las obligaciones.'
          );
        })
        .finally(() => (this.isLoadingPaymentStatus = false));
    },
  },
  created() {
    this.$store.dispatch('getIp');
    this.$store.dispatch('getFinancialTips');
    this.fetchDataGenaral();
  },
});
