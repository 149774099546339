import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosService } from '@/services/axios.service';
import { CouponParamsI } from '../interfaces/digital-coupon.interface';

export class CouponService {
  private service: AxiosInstance;

  constructor() {
    this.service = new AxiosService().instance();
  }

  async getDigitalCoupon(payload: CouponParamsI) {
    const requestConfig: AxiosRequestConfig = {};

    if (payload.token) {
      requestConfig.headers = {
        Authorization: `Bearer ${payload.token}`,
      };
    }

    return this.service.post(
      `api/coupons/get-data-coupons-by-entity`,
      payload.params,
      requestConfig
    );
  }
}
